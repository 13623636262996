
import { Component, Vue } from 'vue-property-decorator';
import GlobalLoader from '@/components/GlobalLoader.vue';
import ptBR from 'ant-design-vue/lib/locale-provider/pt_BR';
import es from 'ant-design-vue/lib/locale-provider/es_ES';
import en from 'ant-design-vue/lib/locale-provider/en_US';
import { PageName } from '@/enums/pageName';
import { checkSupported } from '@/services/unsupportedBrowser';

@Component({
  components: {
    GlobalLoader
  }
})
export default class App extends Vue {
  locales = {
    en: en,
    ['pt-BR']: ptBR,
    es
  };

  mounted(): void {
    if (!checkSupported() && this.$router.currentRoute.name !== PageName.UNSUPPORTED_BROWSER) {
      this.$router.push({ name: PageName.UNSUPPORTED_BROWSER });
    }
  }

  get loaderTip(): string {
    return null;
  }

  private userSnapCommentTemplate =
    'Title: \n\n' +
    '🔴 Actual result (what you see):\n\n' +
    '🟢 Desired result (what you expect to see):\n\n' +
    '🔃 Steps to reproduce (what you did to get the actual result):\n';

  get locale() {
    return this.locales[this.$root.$i18n.locale] || this.locales.en;
  }

  openFeedback(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const userSnap = (window as any).userSnap;
    if (userSnap) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      userSnap.on('open', (event: any) => {
        let template = this.userSnapCommentTemplate;

        event.api.setValue('visitor', this.$store.state.userInfo ? this.$store.state.userInfo.Email : null);
        event.api.setValue('comment', template);
        event.api.setValue('custom', this.$store.state.userInfo);
      });
      userSnap.logEvent('report_issue');
    }
  }
}
