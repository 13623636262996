import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {
  Dropdown,
  Button,
  Menu,
  Input,
  Icon,
  AutoComplete,
  Select,
  Tabs,
  Breadcrumb,
  Radio,
  Collapse,
  Col,
  Row,
  Divider,
  DatePicker,
  Table,
  Checkbox,
  Layout,
  Switch,
  PageHeader,
  Card,
  FormModel,
  Form,
  ConfigProvider,
  Slider,
  Spin,
  Popover,
  Pagination,
  Tooltip,
  Alert,
  Modal,
  Carousel,
  Tag
} from 'ant-design-vue';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import VueI18n from 'vue-i18n';
import en from '@/i18n/en.json';
import es from '@/i18n/es.json';
import ptBr from '@/i18n/pt-BR.json';
import constants from '@/services/constants';
import moment from 'moment-timezone';

Vue.use(Dropdown);
Vue.use(Select);
Vue.use(Button);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(AutoComplete);
Vue.use(Input);
Vue.use(Tabs);
Vue.use(Breadcrumb);
Vue.use(Radio);
Vue.use(Collapse);
Vue.use(Col);
Vue.use(Row);
Vue.use(Divider);
Vue.use(DatePicker);
Vue.use(Table);
Vue.use(Checkbox);
Vue.use(Layout);
Vue.use(Switch);
Vue.use(PageHeader);
Vue.use(Card);
Vue.use(FormModel);
Vue.use(Form);
Vue.use(ConfigProvider);
Vue.use(Slider);
Vue.use(Popover);
Vue.use(Pagination);
Vue.use(Spin);
Vue.use(Tooltip);
Vue.use(Alert);
Vue.use(VueI18n);
Vue.use(Modal);
Vue.use(Carousel);
Vue.use(Tag);

Vue.config.productionTip = false;

moment.tz.setDefault('UTC');

const injectUserSnap = () => {
  (window as any).onUsersnapCXLoad = function (api) {
    api.init({ button: null });
    (window as any).userSnap = api;
  };
  const script = document.createElement('script') as any;
  script.defer = 1;
  script.src = 'https://widget.usersnap.com/global/load/348480da-d057-4813-a9ab-d1221d33e825?onload=onUsersnapCXLoad';
  document.getElementsByTagName('head')[0].appendChild(script);
};
injectUserSnap();

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: ['Request failed with status code 403', 'Request failed with status code 404'],
    logErrors: true,
    environment: process.env.VUE_APP_ENVIRONMENT,
    debug: process.env.VUE_APP_ENVIRONMENT !== 'prod',
    release: process.env.VUE_APP_RELEASE,
    integrations: [
      new BrowserTracing({
        // TODO: we don't track backend APIs now as it's creating too many OPTIONS/Preflight request
        tracingOrigins: ['dont-track-anything']
      })
    ],
    trackComponents: true,
    tracesSampleRate: 0.1
  });
}

const i18n = new VueI18n({
  locale: localStorage.getItem(constants.LOCAL_STORAGE_KEYS.LANG) || 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    'pt-BR': ptBr,
    es
  }
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
